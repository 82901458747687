import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import { MenuSettings } from '@models/menu-settings/menu-settings';
import { SiblingsLockedValues } from '@models/partner/menu/menu-icons';
import { CreateNavigationItemRequest, NavigationItemResponse } from '@models/partner/menu/navigation-item';
import { OptionSelectInputResources } from '@models/partner/menu/option-select-input-resource';
import {
  MenuType,
  NavigationType,
  OrderingItemWithChildren,
  PlanGroupMenuResponse,
} from '@models/partner/menu/plan-group-menu-response';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MenuWebservice {
  readonly #http = inject(HttpClient);
  readonly #baseUrl = `${environment.api.obendy}/v1/partner-revision`;

  public readonly getSettings = (partnerId: string): Observable<MenuSettings> =>
    this.#http.get<MenuSettings>(`${this.#baseUrl}/${partnerId}/menu-settings`);

  public readonly getPlanGroupMenu = ({
    partnerId,
    planGroupId,
    menuType,
  }: {
    partnerId: string;
    planGroupId: string;
    menuType: MenuType;
  }): Observable<PlanGroupMenuResponse> =>
    this.#http.get<PlanGroupMenuResponse>(
      `${environment.api.obendy}/v2/partner-revision/${partnerId}/plan-group/${planGroupId}/menu/${menuType}`
    );

  public readonly deleteMenuEntry = ({
    partnerId,
    planGroupId,
    menuType,
    entryId,
  }: {
    partnerId: string;
    planGroupId: string;
    menuType: MenuType;
    entryId: string;
  }): Observable<void> =>
    this.#http.delete<void>(
      `${environment.api.obendy}/v2/partner-revision/${partnerId}/plan-group/${planGroupId}/menu/${menuType}/menu-item/${entryId}`
    );

  public readonly reorderMenu = ({
    partnerId,
    planGroupId,
    menuType,
    navigationType,
    ordering,
  }: {
    partnerId: string;
    planGroupId: string;
    menuType: MenuType;
    navigationType: NavigationType;
    ordering: Array<OrderingItemWithChildren>;
  }): Observable<PlanGroupMenuResponse> =>
    this.#http.patch<PlanGroupMenuResponse>(
      `${this.#baseUrl}/${partnerId}/plan-group/${planGroupId}/menu/${menuType}/${navigationType}/items-reordering`,
      { ordering }
    );

  public readonly getInternalRoutes = ({
    partnerId,
    planGroupId,
  }: {
    partnerId: string;
    planGroupId: string;
  }): Observable<OptionSelectInputResources> =>
    this.#http.get<OptionSelectInputResources>(
      `${this.#baseUrl}/${partnerId}/plan-group/${planGroupId}/internal-links-select`
    );

  public readonly getNavigationItem = ({
    partnerId,
    planGroupId,
    menuEntryId,
    menuType,
  }: {
    partnerId: string;
    planGroupId: string;
    menuEntryId: string;
    menuType: MenuType;
  }): Observable<NavigationItemResponse> =>
    this.#http.get<NavigationItemResponse>(
      `${environment.api.obendy}/v2/partner-revision/${partnerId}/plan-group/${planGroupId}/menu/${menuType}/item/${menuEntryId}`
    );

  public readonly getSiblingLockedValues = ({
    partnerId,
    planGroupId,
    menuType,
    navigationType,
    itemId,
  }: {
    partnerId: string;
    planGroupId: string;
    menuType: MenuType;
    navigationType: NavigationType;
    itemId: string | null;
  }): Observable<SiblingsLockedValues> => {
    if (itemId) {
      return this.#http.get<SiblingsLockedValues>(
        `${this.#baseUrl}/${partnerId}/plan-group/${planGroupId}/menu/${menuType}/${navigationType}/sibling-values?itemId=${itemId}`
      );
    }
    return this.#http.get<SiblingsLockedValues>(
      `${this.#baseUrl}/${partnerId}/plan-group/${planGroupId}/menu/${menuType}/${navigationType}/sibling-values`
    );
  };

  public readonly createNavigationItem = ({
    partnerId,
    planGroupId,
    menuType,
    navigationType,
    payload,
  }: {
    partnerId: string;
    planGroupId: string;
    menuType: MenuType;
    navigationType: NavigationType;
    payload: CreateNavigationItemRequest;
  }): Observable<NavigationItemResponse> =>
    this.#http.post<NavigationItemResponse>(
      `${this.#baseUrl}/${partnerId}/plan-group/${planGroupId}/menu/${menuType}/${navigationType}`,
      payload
    );

  public readonly updateNavigationItem = ({
    partnerId,
    planGroupId,
    menuType,
    itemId,
    payload,
  }: {
    partnerId: string;
    planGroupId: string;
    menuType: MenuType;
    itemId: string;
    payload: CreateNavigationItemRequest;
  }): Observable<NavigationItemResponse> =>
    this.#http.put<NavigationItemResponse>(
      `${this.#baseUrl}/${partnerId}/plan-group/${planGroupId}/menu/${menuType}/item/${itemId}`,
      payload
    );
}
